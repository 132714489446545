exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-geral-js": () => import("./../../../src/pages/geral.js" /* webpackChunkName: "component---src-pages-geral-js" */),
  "component---src-pages-males-js": () => import("./../../../src/pages/males.js" /* webpackChunkName: "component---src-pages-males-js" */),
  "component---src-templates-form-educator-js": () => import("./../../../src/templates/form-educator.js" /* webpackChunkName: "component---src-templates-form-educator-js" */),
  "component---src-templates-form-fanon-js": () => import("./../../../src/templates/form-fanon.js" /* webpackChunkName: "component---src-templates-form-fanon-js" */),
  "component---src-templates-form-js": () => import("./../../../src/templates/form.js" /* webpackChunkName: "component---src-templates-form-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */)
}

